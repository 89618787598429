
:root {
  --background-color-light: #ffffff;
  --background-color-dark: #000E2A;
  --menu-icon-color-dark: #ffffff;
  --menu-icon-color-light: #111111;
  --about-color-light: #045CFF;;
  --about-color-dark: #000715;
  --text-color: #111111;
  --header-text-light: #045CFF;;
  --header-text-dark: #F8F8FF;
}


[data-theme='dark'] {
  --background-color: var(--background-color-dark);
  --about-color: var(--about-color-dark);
  --text-color: #F8F8FF;
  --menu-icon-color: var(--menu-icon-color-dark);
  --header-text-color: var(--header-text-dark);
}

[data-theme='light'] {
  --background-color: var(--background-color-light);
  --about-color: var(--about-color-light);
  --text-color: #111111;
  --menu-icon-color: var(--menu-icon-color-light);
  --header-text-color: var(--header-text-light);
}




body {
  width: 100%;
  margin: 0 auto;
  /*background-color: var(--background-color);*/
  z-index: 1000;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh; 
  height: calc(100vh - env(safe-area-inset-bottom)); 
}

/* NavBar */

li {
  list-style: none;
  
}

nav ul li {
  display: inline-block;
  padding: 24px;
}

a {
  text-decoration: none;
  color: var(--text-color);
}


.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5%;
  /*background-color: var(--background-color);
  color: var(--text-color);*/
  z-index: 1001;

}


.navbar img {
  width: 150px;
  height: auto;
  
}

.menu-icon {
  cursor: pointer;
  display: none;
}

.nav-links ul{
  display: flex;
  list-style: none;
}




.bar {
  width: 25px;
  height: 3px;
  background-color: var(--menu-icon-color);
  margin: 5px 0;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    align-items: center;
  }

  .menu-icon {
    display: block;
    margin-top: 10px;
    
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 80px;
    right: 0;
    /*background-color: var(--background-color);*/
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 110%;
    transition: all 0.3s ease-in-out;
  }

  .nav-links ul {
    display: flex;
    flex-direction: column;
  }

  .nav-links.visible {
    display: flex;
    flex-direction: column;
  }

  nav ul li {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

 
  
}


/* header */



.header {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  /*background-color: var(--background-color);*/
  padding: 50px;  
} 

.header h1 {
  font-weight: 100;
  font-size: 5em;
  /*color: var(--header-text-color);*/
  text-align: center;
  margin: 100px;
  padding-top: 80px;
  white-space: pre-line;
  
}

@media(max-width: 768px) {
  .header h1 {
    font-size: 3em;
  }
}


/* about */

.about-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 10%;
  /*background-color: var(--about-color);
  color: #F8F8FF;*/
}


.about-content {
  margin: 100px;
  text-align: center;
  line-height: 2.5;
  max-width: 800px;
}

.about-content h2 {
  font-size: 2em;
}

.about-content p {
  font-size: 0.9em;
}

@media(max-width: 768px) {
  .about-content h2{
    line-height: 2.0;
  }
}

/* footer */
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 5%;
  /*background-color: var(--background-color);*/
  /*color: var(--text-color);*/
  
}

.footer-container p {
  text-align: center;
  font-size: 13px;
}

/* Dark/light mode button*/
.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
  
}



.theme-toggle.dark {
  color: #F8F8FF;
}

.theme-toggle.light {
  color:  #111111;
}

